<template>
  <b-modal ref="BodyFatResetImagesConfirmPopup" :no-enforce-focus="true" size="md">
      <template #modal-title>
          <div class="mr-4">
              Image Reset
          </div>
      </template>
      <div>
        <p>
          This will reset body fat images to its default settings along with the ranges. Please note that this action is irreversible and applicable for both male and female sections.
        </p>
        <p>
          <b>Note:</b> If you have any custom image setup, it is not advisable to reset prior to making sure that you intend to use our system defaults
        </p>
      </div>
      <template #modal-footer>
          <div class="w-100 text-right">
              <b-button
                      variant="secondary"
                      size="md"
                      @click="close"
              >
                  Cancel
              </b-button>
              <b-button
                      variant="secondary"
                      size="md"
                      @click="confirmAction"
              >
                  Confirm
              </b-button>
          </div>
      </template>
  </b-modal>
</template>

<script>
export default {
  name: 'BodyFatResetImagesConfirmPopup',
  computed: {
    showPopup() {
      return this.$store.getters['bodyFatResetPopup/getShowStatus']
    }
  },
  watch: {
    showPopup() {
      if (this.showPopup) {
        this.$refs['BodyFatResetImagesConfirmPopup'].show()
        this.$store.commit('bodyFatResetPopup/setShowStatus', false)
      }
    }
  },
  methods: {
    close () {
        this.$refs['BodyFatResetImagesConfirmPopup'].hide()
    },
    confirmAction () {
      this.$emit('confirmed')
      this.$refs['BodyFatResetImagesConfirmPopup'].hide()
    }
  }
}
</script>